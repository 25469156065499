import StandardData from './standard_data.json';


export default function calculateConformity(inputValues, targetTable, targetPerf){
    let densityTable =  StandardData.densityTables.find(x=>x.type === targetTable);
    let perfLevel = StandardData.performanceLevels.find(x=>x.type === targetPerf.slice(2));

    let returnData = [];

    if(!inputValues) return null;

    //0deg-0deg değeri
    let refValue = inputValues["h0v0"];
    let refValid = false;
    let targetedRefValue = perfLevel.Imin;

    if(perfLevel.Imax >= refValue && refValue >= perfLevel.Imin){ //Referans istenen aralık içindeyse
        refValid = true;
        if(targetPerf.startsWith("B")) targetedRefValue = perfLevel.Imin; //B sınıfı ise hedeflenen ref değeri Imin değeri
        if(targetPerf.startsWith("A")) targetedRefValue = refValue; //A sınıfı ise hedeflenen ref değeri okunan ref değeri
    }   

    Object.keys(inputValues).forEach(key => {
        let inputValue = inputValues[key];
        let densityRequirement = densityTable.requiredValues[key];
        let minimumRequiredValue = key=="h0v0" ? perfLevel.Imin : Math.round(targetedRefValue * (parseFloat(densityRequirement) / 100.0));
        let maximumRequiredValue = perfLevel.Imax;
        let tooLow = inputValue < minimumRequiredValue;
        let tooHigh = inputValue > maximumRequiredValue;

        if(!densityRequirement) return;
        
        returnData.push({
            key,
            inputValue,
            minimumRequiredValue,
            maximumRequiredValue,
            tooHigh,
            tooLow
        });
    });

    let isGood = !returnData.some(x=>x.tooHigh || x.tooLow);
    
    return {
        type: `${targetTable}_${targetPerf}`,
        isGood,
        targetedRefValue,
        returnData
    };
}