import "./table.css";
import { Formik } from 'formik';
import { useEffect } from 'react';
import { Table, Input, Label, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from 'semantic-ui-react';


function InputTableRenderer(params) {
    let { calculationData, inputValues, setInputValues } = params;

    let rows = [0, "-1p5", -3, -5, -10, -20];
    let cols = [-30, -20, -15, -10, -5, "-2p5", 0, "2p5", 5, 10, 15, 20, 30];
    let tableCells = ["h-30v0", "h-30v-20", "h-20v0", "h-20v-10", "h-10v0", "h-10v-5", "h-5v0", "h-5v-3", "h0v0", "h0v-3", "h0v-5", "h0v-10", "h0v-20", "h5v0", "h5v-3", "h10v0", "h10v-5", "h20v0", "h20v-10", "h30v0", "h30v-20", "h15v0", "h-15v0", "h0v-1p5", "h2p5v0", "h-2p5v0", "h2p5v-1p5", "h-2p5v-1p5", "h15v-10", "h-15v-10"];

    let initialValues = {};
    if (inputValues != null) {
        initialValues = inputValues;
    } else {
        cols.forEach(c => {
            rows.forEach(r => {
                initialValues[`h${c}v${r}`] = 0;
            });
        });
    }

    useEffect(() => {
        setInputValues(initialValues);
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validateOnBlur={true}
            validate={values => {
                setInputValues(values);
            }}

        >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        }) => (

            <form onSubmit={handleSubmit}>
                <Table definition structured celled style={{ overflow: 'visible' }}>
                    <TableHeader fullWidth={true}>
                        <TableRow>
                            <TableHeaderCell className="background">
                                <div>
                                    <span className="top">Yatay</span>
                                    <span className="bottom">Dikey</span>
                                    <div className="line"></div>
                                </div>
                            </TableHeaderCell>
                            {cols.map((colVal, index) => (<TableHeaderCell style={{ width: 100, textAlign: 'center' }} key={index}><strong>{String(colVal).replace("p", ".")}°</strong></TableHeaderCell>))}
                        </TableRow>
                    </TableHeader>
                    <TableBody style={{ overflow: 'visible' }}>
                        {rows.map((rowVal, index) => {
                            return (
                                <TableRow key={"row_" + index} className="position-relative" style={{ overflow: 'visible' }}>
                                    <TableCell key={index + "_1"} style={{ padding: 0 }}>
                                        <strong style={{ width: "100px", display: "block", textAlign: 'center', verticalAlign: 'center' }}>{String(rowVal).replace("p", ".")}°</strong>
                                    </TableCell>
                                    {cols.map((colVal, index) => {
                                        let cellKey = `h${colVal}v${rowVal}`;

                                        if (!tableCells.includes(cellKey)) {
                                            return (<TableCell key={cellKey}></TableCell>);
                                        }

                                        let state = 0; //0 means nothing, 1 means good, 2 means error
                                        let isDefined = false;
                                        let minVal = 0;
                                        let maxVal = 0;

                                        if (calculationData && calculationData.returnData) {
                                            let calculatedCellData = calculationData.returnData.find(x => x.key === cellKey);
                                            if (calculatedCellData) {
                                                isDefined = true;

                                                minVal = calculatedCellData.minimumRequiredValue;
                                                maxVal = calculatedCellData.maximumRequiredValue;

                                                if (calculatedCellData.tooHigh || calculatedCellData.tooLow)
                                                    state = 2;
                                                else
                                                    state = 1;


                                            }
                                        }

                                        return (
                                            <TableCell key={cellKey} style={{ padding: 0, overflow: 'visible' }}>
                                                <div style={{
                                                    padding: 5,
                                                    textAlign: 'center'
                                                }}>
                                                    <Input
                                                        style={{ marginBottom: 5 }}
                                                        fluid
                                                        type="number"
                                                        name={`h${colVal}v${rowVal}`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[cellKey]}
                                                        className={state === 1 ? "success" : state === 2 ? "error" : null}
                                                        // 

                                                    />
                                                    {isDefined && state === 2 ? (
                                                        <Label basic fluid="true" color="red">
                                                            {`${minVal}<x<${maxVal}`}
                                                        </Label>
                                                    ) : null}

                                                    {isDefined && state === 1 ? (
                                                        <Label basic fluid="true" color="green" >
                                                            {`${minVal}<x<${maxVal}`}
                                                        </Label>
                                                    ) : null}


                                                    {state === 0 && calculationData ? (
                                                        <Label fluid="true">
                                                            Aranmıyor
                                                        </Label>
                                                    ) : null}

                                                    {/* <Form.Field type="invalid" size="sm">
                                                        
                                                    </Form.Field>

                                                    <Form.Field type="valid" size="sm">
                                                        {isDefined ? `${minVal}<x<${maxVal}` : ""}
                                                    </Form.Field> */}
                                                </div>

                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </form>
        )}
        </Formik>

    );
}

export default InputTableRenderer;