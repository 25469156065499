import StandardData from './standard_data.json';
import readXlsxFile from 'read-excel-file'

const INPUT_ROWS = [0, "-1p5", -3, -5, -10, -20];
const INPUT_COLS = [-30, -20, -15, -10, -5, "-2p5", 0, "2p5", 5, 10, 15, 20, 30];
const INPUT_CELLS = ["h-30v0", "h-30v-20", "h-20v0", "h-20v-10", "h-10v0", "h-10v-5", "h-5v0", "h-5v-3", "h0v0", "h0v-3", "h0v-5", "h0v-10", "h0v-20", "h5v0", "h5v-3", "h10v0", "h10v-5", "h20v0", "h20v-10", "h30v0", "h30v-20", "h15v0", "h-15v0", "h0v-1p5", "h2p5v0", "h-2p5v0", "h2p5v-1p5", "h-2p5v-1p5", "h15v-10", "h-15v-10"];

export async function ReadDataExport(file) {
    let rows = await readXlsxFile(file);
    // console.log(rows, rows.length);
    if(rows.length != 9) throw new Error("Dosya formatı hatalı");
    
    let newInputValues = {};

    for (let colIndex = 2; colIndex < rows[0].length; colIndex++) {
        for (let rowIndex = 2; rowIndex < rows.length - 1; rowIndex++) {
            try {
                let cellCode = `h${rows[0][colIndex]}v${rows[rowIndex][0]}`.replace(".", "p").replace(".", "p").replace(".", "p");
                let cellValue = rows[rowIndex][colIndex];
                if (INPUT_CELLS.includes(cellCode)) {
                    newInputValues[cellCode] = cellValue;
                }

            }
            catch (err) {
                console.log(err);
            }
        }
    }

    if(Object.keys(newInputValues).length !== INPUT_CELLS.length){
        console.log("Alert, length doesn't match", Object.keys(newInputValues).length, INPUT_CELLS.length);
    }
    // console.log(newInputValues);
    return newInputValues;

    // let data = [];
    // let headerRow = [
    //     null,
    //     {
    //         value: "Yatay",
    //         align: "center",
    //         fontWeight: 'bold',
    //         backgroundColor: "#FEFEDE",
    //         borderStyle: "thin",
    //     },
    //     ...INPUT_COLS.map((colVal, index) => {
    //         return {
    //             value: String(colVal).replace("p", "."),
    //             align: "center",
    //             fontWeight: 'bold',
    //             rowSpan: 2,
    //             backgroundColor: "#FEFEDE",
    //             borderStyle: "thin",
    //             alignVertical: "center"
    //         }
    //     })
    // ];

    // let headerRow2 = [
    //     {
    //         value: "Dikey",
    //         align: "center",
    //         fontWeight: 'bold',
    //         borderStyle: "thin",
    //         backgroundColor: "#DEFEFE",
    //     },
    //     null,
    //     ...Array.from({ length: INPUT_COLS.length }).map((_, index) => null)
    // ];

    // data.push(headerRow);
    // data.push(headerRow2);

    // INPUT_ROWS.forEach((rowVal, index) => {
    //     let rowCells = [{
    //         value: String(rowVal).replace("p", "."),
    //         align: "center",
    //         fontWeight: 'bold',
    //         span: 2,
    //         backgroundColor: "#DEFEFE",
    //         borderStyle: 'thin',
    //         alignVertical: "center",
    //         height: 25
    //     }, null];

    //     {INPUT_COLS.map((colVal, index) => {
    //         let cellKey = `h${colVal}v${rowVal}`;

    //         if (!INPUT_CELLS.includes(cellKey)) {

    //             rowCells.push({
    //                 value: "*", 
    //                 align: "center",
    //                 borderStyle: 'thin',
    //                 backgroundColor: '#DEDEDE',
    //                 color: '#999999',
    //                 alignVertical: "center",
    //                 height: 25
    //             });

    //             return;
    //         }

    //         rowCells.push({
    //             value: inputValues[cellKey],
    //             align: "center",
    //             borderStyle: 'thin',
    //             format: "0",
    //             alignVertical: "center",
    //             height: 25
    //         });
    //     })}

    //     data.push(rowCells);
    // });

    // data.push([
    //     {
    //         value: "Dikkat! Bu tablo program tarafından okunup yazıldığı için sadece beyaz hücrelerdeki değerleri değiştiriniz. Sadece nümerik değerler giriniz.",
    //         fontWeight: "bold",
    //         fontSize: 10,
    //         span: (INPUT_COLS.length + 2)
    //     }
    // ]);


    // let _fileName = prompt("Dosya Adını Belirleyin", "Olcum_Degerleri")

    // // await writeXlsxFile(data, {
    // //     fileName: _fileName + '.xlsx'
    // // });
}