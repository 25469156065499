export default function UploadFileFunction(callback){
    var input = document.createElement("input");
    input.type = "file";
    // input.accept = 'text/plain'; // Set the accept attribute to limit to text files

    input.addEventListener("change", (evt) => {
        var file = evt.target.files[0];
        callback(file);
    });
    input.click();
};