import StandardData from './standard_data.json';
import writeXlsxFile from 'write-excel-file';

const INPUT_ROWS = [0, "-1p5", -3, -5, -10, -20];
const INPUT_COLS = [-30, -20, -15, -10, -5, "-2p5", 0, "2p5", 5, 10, 15, 20, 30];
const INPUT_CELLS = ["h-30v0", "h-30v-20", "h-20v0", "h-20v-10", "h-10v0", "h-10v-5", "h-5v0", "h-5v-3", "h0v0", "h0v-3", "h0v-5", "h0v-10", "h0v-20", "h5v0", "h5v-3", "h10v0", "h10v-5", "h20v0", "h20v-10", "h30v0", "h30v-20", "h15v0", "h-15v0", "h0v-1p5", "h2p5v0", "h-2p5v0", "h2p5v-1p5", "h-2p5v-1p5", "h15v-10", "h-15v-10"];

export async function GenerateDataExport(inputValues) {
    let data = [];
    let headerRow = [
        null,
        {
            value: "Yatay",
            align: "center",
            fontWeight: 'bold',
            backgroundColor: "#FEFEDE",
            borderStyle: "thin",
        },
        ...INPUT_COLS.map((colVal, index) => {
            return {
                value: String(colVal).replace("p", "."),
                align: "center",
                fontWeight: 'bold',
                rowSpan: 2,
                backgroundColor: "#FEFEDE",
                borderStyle: "thin",
                alignVertical: "center"
            }
        })
    ];

    let headerRow2 = [
        {
            value: "Dikey",
            align: "center",
            fontWeight: 'bold',
            borderStyle: "thin",
            backgroundColor: "#DEFEFE",
        },
        null,
        ...Array.from({ length: INPUT_COLS.length }).map((_, index) => null)
    ];

    data.push(headerRow);
    data.push(headerRow2);

    INPUT_ROWS.forEach((rowVal, index) => {
        let rowCells = [{
            value: String(rowVal).replace("p", "."),
            align: "center",
            fontWeight: 'bold',
            span: 2,
            backgroundColor: "#DEFEFE",
            borderStyle: 'thin',
            alignVertical: "center",
            height: 25
        }, null];

        {
            INPUT_COLS.map((colVal, index) => {
                let cellKey = `h${colVal}v${rowVal}`;

                if (!INPUT_CELLS.includes(cellKey)) {

                    rowCells.push({
                        value: "*",
                        align: "center",
                        borderStyle: 'thin',
                        backgroundColor: '#DEDEDE',
                        color: '#999999',
                        alignVertical: "center",
                        height: 25
                    });

                    return;
                }

                rowCells.push({
                    value: inputValues[cellKey],
                    align: "center",
                    borderStyle: 'thin',
                    alignVertical: "center",
                    height: 25
                });
            })
        }

        data.push(rowCells);
    });

    data.push([
        {
            value: "Dikkat! Bu tablo program tarafından okunup yazıldığı için sadece beyaz hücrelerdeki değerleri değiştiriniz. Sadece nümerik değerler giriniz.",
            fontWeight: "bold",
            fontSize: 10,
            span: (INPUT_COLS.length + 2)
        }
    ]);


    let _fileName = prompt("Dosya Adını Belirleyin", "Olcum_Degerleri")
    if(!_fileName) return;

    await writeXlsxFile(data, {
        fileName: _fileName + '.xlsx'
    });
}


export async function GenerateExcelReport(inputValues, calculationData) {
    let mainPage = PrepareFirstPage(inputValues, calculationData);

    let individualMeasurementPages = calculationData.map(item => PrepareMeasureResultPages(inputValues, item));

    let _fileName = prompt("Dosya Adını Belirleyin", "Kapsamli_Rapor")
    if(!_fileName) return;

    await writeXlsxFile(
        [mainPage, ...individualMeasurementPages], {
        sheets: ["Genel Ölçüm Sonuçları", ...calculationData.map(x => `Tip ${x.type[0]} ${x.type.substring(2).replace('/', '-')} (${x.isGood ? "Geçti" : "Kaldı"})`)],
        headerStyle: [ {backgroundColor: '#FF0000'}, ...calculationData.map(x => {return {backgroundColor: x.isGood ? "#00FF00" : "#FF0000"}})],
        columns: [[{}], ...calculationData.map(x=> [{},{width: 14}])],
        fileName: _fileName + '.xlsx'
    });
}


function PrepareFirstPage(inputValues, calculationData) {
    let data = [];
    //First Row
    data.push([
        {
            value: 'Açısal Dağılım',
            fontWeight: 'bold',
            borderStyle: "thin",
            align: "center",
            span: 3
        },
        null,
        null,
        {
            value: 'Işık Performansı Seviyesi',
            fontWeight: 'bold',
            span: 10,
            borderStyle: "thin",
            align: "center",
        }
    ]);

    //Data rows
    StandardData.densityTables.forEach(table => {
        let rowCells = [];

        rowCells.push({
            value: `Tip ${table.type} (${table.description})`,
            borderStyle: "thin",
            align: "center",
            span: 3
        });
        rowCells.push(null);
        rowCells.push(null);

        Array.from({ length: 10 }).map((_, index) => {
            // if (typeof table.allowedPerformanceLevels[index] === "undefined") return;

            let cellColor = "#FFFFFF";
            let cellTypeCode = table.type + "_" + table.allowedPerformanceLevels[index];
            let cellPerfLevel = table.allowedPerformanceLevels[index] ?? "";
            if (calculationData.length > 0) {
                let cellCalcData = calculationData.find(x => x && x.type === cellTypeCode);
                if (cellCalcData && cellCalcData?.isGood) cellColor = "#00FF00";
                if (cellCalcData && !cellCalcData?.isGood) cellColor = "#FF0000";
            }

            rowCells.push({
                value: cellPerfLevel,
                backgroundColor: cellColor,
                borderStyle: "thin",
                align: "center",
            })
        });

        data.push(rowCells);
    });


    data.push([]);

    let headerRow = [
        null,
        {
            value: "Yatay",
            align: "center",
            fontWeight: 'bold',
            backgroundColor: "#FEFEDE",
            borderStyle: "thin",
        },
        ...INPUT_COLS.map((colVal, index) => {
            return {
                value: String(colVal).replace("p", "."),
                align: "center",
                fontWeight: 'bold',
                rowSpan: 2,
                backgroundColor: "#FEFEDE",
                borderStyle: "thin",
                alignVertical: "center"
            }
        })
    ];

    let headerRow2 = [
        {
            value: "Dikey",
            align: "center",
            fontWeight: 'bold',
            borderStyle: "thin",
            backgroundColor: "#DEFEFE",
        },
        null,
        ...Array.from({ length: INPUT_COLS.length }).map((_, index) => null)
    ];

    data.push(headerRow);
    data.push(headerRow2);

    INPUT_ROWS.forEach((rowVal, index) => {
        let rowCells = [{
            value: String(rowVal).replace("p", "."),
            align: "center",
            fontWeight: 'bold',
            span: 2,
            backgroundColor: "#DEFEFE",
            borderStyle: 'thin',
            alignVertical: "center",
            height: 25
        }, null];

        {
            INPUT_COLS.map((colVal, index) => {
                let cellKey = `h${colVal}v${rowVal}`;

                if (!INPUT_CELLS.includes(cellKey)) {

                    rowCells.push({
                        value: "*",
                        align: "center",
                        borderStyle: 'thin',
                        backgroundColor: '#DEDEDE',
                        color: '#999999',
                        alignVertical: "center",
                        height: 25
                    });

                    return;
                }

                rowCells.push({
                    value: inputValues[cellKey],
                    align: "center",
                    borderStyle: 'thin',
                    alignVertical: "center",
                    height: 25
                });
            })
        }

        data.push(rowCells);
    });
    return data;
}


function PrepareMeasureResultPages(inputValues, calcData) {
    let data = [];

    {
        let firstTableHeader = [
            {
                value: 'Işık Performans Seviyesi',
                fontWeight: 'bold',
                borderStyle: "thin",
                align: "center",
                span: 3
            },
            null,
            null,
            {
                value: 'Sonuç',
                fontWeight: 'bold',
                span: 2,
                borderStyle: "thin",
                align: "center",
            },
            null
        ];

        let table_type = calcData.type[0];
        let perf_level = calcData.type.substring(2);

        let firstTableDataRow = [
            {
                value: `Tip ${table_type} ${perf_level}`,
                borderStyle: "thin",
                align: "center",
                span: 3
            },
            null,
            null,
            {
                value: calcData.isGood ? 'Geçti' : "Kaldı",
                color: calcData.isGood ? "#21ba45" : "#db2828",
                fontWeight: 'bold',
                span: 2,
                borderStyle: "thin",
                align: "center",
            },
            null
        ];


        data.push(firstTableHeader, firstTableDataRow, []);
    }

    {
        let headerRow = [
            null,
            {
                value: "Yatay",
                align: "center",
                fontWeight: 'bold',
                backgroundColor: "#FEFEDE",
                borderStyle: "thin",
            },
            ...INPUT_COLS.map((colVal, index) => {
                return {
                    value: String(colVal).replace("p", "."),
                    align: "center",
                    fontWeight: 'bold',
                    rowSpan: 2,
                    backgroundColor: "#FEFEDE",
                    borderStyle: "thin",
                    alignVertical: "center"
                }
            })
        ];

        let headerRow2 = [
            {
                value: "Dikey",
                align: "center",
                fontWeight: 'bold',
                borderStyle: "thin",
                backgroundColor: "#DEFEFE",
            },
            null,
            ...Array.from({ length: INPUT_COLS.length }).map((_, index) => null)
        ];

        data.push(headerRow);
        data.push(headerRow2);

        INPUT_ROWS.forEach((rowVal, index) => {
            let row1 = [
                {
                    value: String(rowVal).replace("p", "."),
                    align: "center",
                    fontWeight: 'bold',
                    rowSpan: 2,
                    backgroundColor: "#DEFEFE",
                    borderStyle: 'thin',
                    alignVertical: "center",
                }, 
                {
                    value: "İstenen Aralık (cd)",
                    align: "center",
                    borderStyle: "thin",
                    alignVertical: "center",
                    height: 20,
                    fontSize: 10
                }
            ];
            let row2 = [
                null,
                {
                    value: "Ölçülen Aralık (cd)",
                    align: "center",
                    borderStyle: "thin",
                    alignVertical: "center",
                    height: 20,
                    fontSize: 10,
                    topBorderStyle: "hair",
                }
            ];


            {
                INPUT_COLS.map((colVal, index) => {
                    let cellKey = `h${colVal}v${rowVal}`;
                    let measureData = calcData.returnData.find(x=>x.key === cellKey);
                    

                    if (!INPUT_CELLS.includes(cellKey)) {

                        row1.push({
                            value: "",
                            align: "center",
                            borderStyle: 'thin',
                            color: '#999999',
                            alignVertical: "center",
                            height: 20
                        });

                        row2.push({
                            value: "",
                            align: "center",
                            borderStyle: 'thin',
                            color: '#999999',
                            alignVertical: "center",
                            height: 20,
                            topBorderStyle: "hair",
                        });

                        return;
                    }

                    let backgroundColor = measureData ? (measureData.tooHigh || measureData.tooLow ? "#db2828": "#21ba45") : "#FFFFFF";
                    let textColor = measureData ? (measureData.tooHigh || measureData.tooLow ? "#FFFFFF": "#FFFFFF") : "#000000";
                    row1.push({
                        value: measureData ? (`${measureData.minimumRequiredValue}<x<${measureData.maximumRequiredValue}`) : "Aranmıyor",
                        align: "center",
                        borderStyle: "thin",
                        backgroundColor: backgroundColor,
                        color: textColor,
                        alignVertical: "center",
                        height: 20,
                        fontSize: 10
                    });
                

                    row2.push({
                        value: inputValues[cellKey],
                        align: "center",
                        borderStyle: 'thin',
                        topBorderStyle: "hair",
                        backgroundColor: backgroundColor,
                        alignVertical: "center",
                        fontWeight: 'bold',
                        color: textColor,
                        height: 20
                    });
                })
            }

            data.push(row1, row2);
        });
    }
    return data;
}
